import React from "react"
import { Link } from "gatsby"
import Layout from "../components/Layout1"
import SEO from "../components/SEO"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not Found" />
    <div className="w-full lg:w-8/12 mx-auto mb-16">
      <h2 className="w-full bg-adobe py-4 mb-10 leading-none items-center font-sans font-bold text-white text-2xl lg:text-4xl text-center">
        NOT FOUND
      </h2>
      <div className="mt-2 px-10  p-with-leading">
        <p className="font-serif font-light text-black text-xl">
          The page you were trying to get to doesn&#39;t exist. We're sorry
          about that!.
        </p>
        <Link
          className="bg-red hover:bg-adobe text-white font-bold font-serif py-2 px-8 text-lg rounded"
          to="/"
        >
          Back to home
        </Link>
      </div>
    </div>
  </Layout>
)

export default NotFoundPage
